import React from 'react';
import { useNavigate } from 'react-router-dom';

function About() {
    const navigate = useNavigate();

    return (
        <section className="relative py-24 px-6 bg-gradient-to-b from-black to-gray-900 text-white">
            <div className="max-w-4xl mx-auto">
                {/* Main Content */}
                <div className="text-center mb-24">
                    <h2 className="text-4xl md:text-5xl font-bold mb-8">
                        Was Lauft bi dier i de nöchi?
                    </h2>
                    <p className="text-lg md:text-xl text-gray-300 leading-relaxed mb-12 max-w-2xl mx-auto">
                        From hidden gem concerts to bustling street festivals,
                        discover everything happening in your neighborhood.
                        Connect with your local community and never miss a moment.
                    </p>
                    <button
                        onClick={() => navigate('/events')}
                        className="group relative inline-flex items-center justify-center px-8 py-3
                                 text-lg font-medium text-black bg-white rounded-full overflow-hidden
                                 transition-all duration-300 hover:scale-105 hover:shadow-lg"
                    >
                        <span className="relative z-10">Find Events</span>
                        <div className="absolute inset-0 -translate-x-full group-hover:translate-x-0
                                      bg-gradient-to-r from-blue-500 to-purple-500 transition-transform
                                      duration-300 ease-out opacity-50">
                        </div>
                    </button>
                </div>

                {/* Contact Section with Development Status */}
                <div className="relative">
                    <div className="absolute inset-0 bg-white/5 backdrop-blur-sm rounded-2xl"></div>
                    <div className="relative p-8 md:p-12 rounded-2xl border border-white/10">
                        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                            <div className="text-center md:text-left flex-1">
                                <h3 className="text-2xl font-bold mb-4">Join the Journey</h3>
                                <p className="text-gray-300">
                                    We're building something special. Want to be part of it?
                                    Reach out to us at{' '}
                                    <a href="mailto:business@was-lauft.com"
                                        className="text-blue-400 hover:text-blue-300 transition-colors">
                                        business@was-lauft.com
                                    </a>
                                </p>
                            </div>
                            <div className="flex items-center gap-4 text-sm">
                                <span className="px-4 py-2 rounded-full bg-blue-500/20 border border-blue-500/30 text-blue-300">
                                    Beta Version
                                </span>
                                <span className="px-4 py-2 rounded-full bg-purple-500/20 border border-purple-500/30 text-purple-300">
                                    Coming Soon
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
