// Hero.js
import React from 'react';

function Hero() {
    return (
        <section className="relative h-screen flex items-center justify-center overflow-hidden">
            {/* Background gif with overlay */}
            <div className="absolute inset-0 z-0">
                <img
                    src="/HeroGif2.gif"
                    alt="Background"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/40 backdrop-blur-[2px]"></div>
            </div>

            {/* Content */}
            <div className="relative z-10 text-center text-white px-6 max-w-4xl mx-auto">
                <div className="inline-block mb-6 px-4 py-1.5 rounded-full border border-white/30 bg-white/10 backdrop-blur-sm">
                    <span className="mr-2">🚧</span>
                    <span className="text-sm font-medium">In Development - Coming Soon</span>
                </div>
                <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
                    Discover What's Next
                </h1>
                <p className="text-xl md:text-2xl mb-8 text-gray-200">
                    Your local events, all in one place
                </p>
            </div>
        </section>
    );
}

export default Hero;
