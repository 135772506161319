// Footer.js
import React from 'react';

function Footer() {
    return (
        <footer className="bg-black text-white py-8 px-6">
            <div className="max-w-4xl mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                    <div className="flex items-center gap-2">
                        <span className="font-bold text-xl">WasLauft</span>
                        <span className="px-2 py-1 text-xs rounded-full bg-gray-800 text-gray-400">
                            Beta
                        </span>
                    </div>
                    <div className="flex items-center gap-6 text-sm text-gray-400">
                        <span>© 2025 WasLauft</span>
                        <div className="h-4 w-px bg-gray-800"></div>
                        <span className="flex items-center gap-2">
                            <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></span>
                            In Development
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
