// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white/90 backdrop-blur-md shadow-sm' : 'bg-transparent'
            }`}>
            <div className="max-w-7xl mx-auto px-6 py-4">
                <div className="flex items-center justify-between">
                    <Link to="/" className="text-2xl font-bold text-gray-800">
                        WasLauft
                    </Link>

                    <div className="hidden md:flex space-x-8">
                        <Link to="/" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Home
                        </Link>
                        <Link to="/events" className="text-gray-600 hover:text-gray-900 transition-colors">
                            Events
                        </Link>
                    </div>

                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="md:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                        <div className="w-6 h-6 flex flex-col justify-between">
                            <span className={`block h-0.5 w-6 bg-black transform transition duration-300 ${isOpen ? 'rotate-45 translate-y-2.5' : ''
                                }`}></span>
                            <span className={`block h-0.5 w-6 bg-black transition-opacity duration-300 ${isOpen ? 'opacity-0' : 'opacity-100'
                                }`}></span>
                            <span className={`block h-0.5 w-6 bg-black transform transition duration-300 ${isOpen ? '-rotate-45 -translate-y-2.5' : ''
                                }`}></span>
                        </div>
                    </button>
                </div>

                {/* Mobile menu */}
                <div className={`md:hidden transition-all duration-300 overflow-hidden ${isOpen ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'
                    }`}>
                    <div className="py-4 space-y-4">
                        <Link to="/" className="block text-gray-600 hover:text-gray-900 transition-colors">
                            Home
                        </Link>
                        <Link to="/events" className="block text-gray-600 hover:text-gray-900 transition-colors">
                            Events
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
}
